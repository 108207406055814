import pick from 'lodash/pick';
import pointer from 'json-pointer';
import { getReference } from '@geomagic/geonam';
import { MutationDeleteDocuments } from '@geomagic/geonam-graphql';

import getDiffs from '@database/getDiffs';
import getFilePropsFromDocumentEntity from '@database/getFilePropsFromDocumentEntity';
import MutationDeleteLocationRecord from '@graphql/mutations/MutationDeleteLocationRecord';
import MutationUpdateFormElement from '@graphql/mutations/MutationUpdateFormElement';

const UPDATE_KEY = 'id';

const updateFormElement = async ({ client, database, doc, entity, entityClasses, mapProps, patchedEntity }) => {
  const patches = doc?.jsonPatch || [];

  for (let i = 0; i < patches.length; i++) {
    const { path, value: update } = patches[i];
    const { id, typename } = update;

    let elementUpdate;

    if (typename === 'FormElementDocuments' || typename === 'FormElementPictures') {
      const newFiles = [];
      const { documents } = pointer.get(entity, path);
      const { documents: patchedDocuments } = update;
      const { added, removed } = getDiffs(documents, patchedDocuments, UPDATE_KEY);

      if (added.length > 0) {
        for (let j = 0; j < added.length; j++) {
          const { hash, name, type } = getFilePropsFromDocumentEntity(added[j]);

          const attachment = await doc.getAttachment(hash);
          const blob = await attachment.getData();
          const file = new File([blob], name, { type });

          newFiles.push(file);
        }

        elementUpdate = { documents: newFiles };
      }

      if (removed.length > 0) {
        await client.mutate({
          mutation: MutationDeleteDocuments,
          variables: { documents: removed.map(item => getReference(item)) },
        });
      }
    }

    if (typename === 'FormElementEnum') {
      elementUpdate = pick(update, 'statusValue');
    }

    if (typename === 'FormElementField') {
      elementUpdate = pick(update, 'value');
    }

    if (typename === 'FormElementLocRecording') {
      const { locationRecords } = pointer.get(entity, path);
      const { locationRecords: patchedLocationRecords } = update;
      const { added, removed } = getDiffs(locationRecords, patchedLocationRecords, UPDATE_KEY);

      if (added.length > 0) {
        elementUpdate = {
          locationRecords: added.map(({ geolocationPositions }) => ({ geolocationPositions })),
        };
      }

      if (removed.length > 0) {
        for (let j = 0; j < removed.length; j++) {
          const { id: removedId } = removed[j];
          await client.mutate({
            mutation: MutationDeleteLocationRecord,
            variables: { id: removedId },
          });
        }
      }
    }

    if (elementUpdate) {
      await client.mutate({
        mutation: MutationUpdateFormElement,
        variables: { formElement: { id, ...elementUpdate } },
      });
    }
  }
};

export default updateFormElement;
