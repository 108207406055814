import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import ListAltIcon from '@material-ui/icons/ListAlt';

import useAppBar from '@components/AppBar/useAppBar';
import FormComponent, { checkFormIsFinished } from '@components/FormComponent';
import useFeatures from '@components/Map/utils/useFeatures';
import { MOBILE_TRIGGER_SIZE } from '@consts';
import getPatch from '@database/getPatch';
import { Trigger } from '@geomagic/core';
import { RELEVANT_DISPATCH_PATH } from '@graphql/consts';
import usePrevious from '@utils/usePrevious';

import getFeatures from './getFeatures';

const FORM_PATH = '/forms';
const TYPENAME = 'Form';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  badge: {
    backgroundColor: palette.status.CLOSED,
  },
  trigger: {
    [breakpoints.down('md')]: {
      height: MOBILE_TRIGGER_SIZE,
      width: MOBILE_TRIGGER_SIZE,
    },
  },
}));

const AssignmentDetail = props => {
  const {
    assignment,
    client,
    entityClasses,
    getFeatureStyle,
    getPreviousMap,
    isMobile,
    isOnline,
    mapProps,
    onOpenTasks,
    stateRef,
    user,
  } = props;
  const classes = useStyles();

  const entity = assignment.getPatchedEntity();
  const { displayName, forms, processInstances = [], relevantDispatches } = entity;
  const { mapRef, selectColor, maxExtentZoomLevel } = mapProps;
  const maxSteps = forms?.length;
  const isFormFinished = checkFormIsFinished(forms);
  const tasks = flatten(processInstances.map(processInstance => processInstance?.tasks));
  const hasTasks = tasks.length > 0;

  const isAssignedToCurrentUser = tasks.map(task => task?.assignee).includes(user.loginName);
  const isCloseable = hasTasks && isFormFinished && isOnline;
  const isClosed = entity.processingStatus.includes('CLOSED');
  const isReadOnly = isClosed || !isAssignedToCurrentUser;

  const { setActions, setTitle } = useAppBar();
  const [activeEditField, setActiveEditField] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [visibleLocationRecordList, setVisibleLocationRecordList] = useState(null);

  const features = useMemo(() => getFeatures([assignment]), [assignment]);

  const activeForm = forms[activeStep];
  const featureCollections = activeForm?.featureCollections;
  const formPath = `${FORM_PATH}/${activeStep}`;

  const prevFeatureCollections = usePrevious(featureCollections);

  /**
   *  EVENT HANDLER
   */

  const handleClickBack = useCallback(() => {
    setActiveStep(prevStep => prevStep - 1);
  }, []);

  const handleClickForward = useCallback(() => {
    setActiveStep(prevStep => prevStep + 1);
  }, []);

  const handleChangeMenu = useCallback((event, index) => {
    setActiveStep(prevStep => {
      return index !== prevStep ? index : prevStep;
    });
  }, []);

  const handleUpdateFormElement = (value, path, data) => {
    const newPatch = {
      op: 'replace',
      path,
      value: data ? { ...data, ...value } : value,
    };

    handleUpdatePatch(newPatch);
  };

  const handleUpdatePatch = async newPatch => {
    const { jsonPatch } = assignment;

    await assignment.atomicUpdate(oldData => {
      oldData.jsonPatch = getPatch(jsonPatch, newPatch);
      return oldData;
    });
  };

  const handleUpdateRelevantDispatches = ({ newDispatch, blockId }, additionalPatch) => {
    const newRelevantDispatches = [
      ...relevantDispatches,
      { ...newDispatch, closed: false, ...(blockId && { blockId }) },
    ];

    const newPatch = {
      op: 'replace',
      path: RELEVANT_DISPATCH_PATH,
      value: newRelevantDispatches,
    };

    handleUpdatePatch(newPatch);
  };

  /**
   *  EFFECTS
   */

  const { selectFeatures } = useFeatures({
    mapRef,
    features,
    isSelectable: false,
    maxExtentZoomLevel,
    selectColor,
    style: getFeatureStyle,
  });

  useEffect(() => {
    setTitle(displayName);
  }, [displayName, setTitle]);

  useEffect(() => {
    const handleOpenTasks = event => {
      event.stopPropagation();
      onOpenTasks && onOpenTasks(assignment);
    };

    const triggerProps = {
      className: classes.trigger,
      color: 'inherit',
    };

    setActions(
      <Trigger
        {...triggerProps}
        icon={
          <Badge classes={{ badge: classes.badge }} invisible={!isCloseable} variant="dot">
            <ListAltIcon />
          </Badge>
        }
        onClick={handleOpenTasks}
      />
    );

    return () => {
      setActions(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCloseable]);

  useEffect(() => {
    if (featureCollections && !isEqual(prevFeatureCollections, featureCollections)) {
      const features = featureCollections[0]?.features;
      features && selectFeatures(features);
    }
  }, [featureCollections, prevFeatureCollections, selectFeatures]);

  /**
   *  FORM CONTEXT
   */

  const context = {
    activeEditField,
    allRelevantDispatches: relevantDispatches,
    client,
    entityClasses,
    getPreviousMap,
    isMobile,
    isOnline,
    isReadOnly,
    mapProps,
    mapRef,
    onUpdate: handleUpdateFormElement,
    onUpateRelevantDispatches: handleUpdateRelevantDispatches,
    stateRef,
    user,
    visibleLocationRecordList,
    setActiveEditField,
    setVisibleLocationRecordList,
  };

  return (
    <FormComponent
      activeStep={activeStep}
      context={context}
      data={{ ...activeForm, typename: TYPENAME }}
      doc={assignment}
      forms={forms}
      disableBack={activeStep === 0}
      disableForward={activeStep === maxSteps - 1}
      onChangeMenu={handleChangeMenu}
      onClickBack={handleClickBack}
      onClickForward={handleClickForward}
      path={formPath}
    />
  );
};

AssignmentDetail.propTypes = {
  assignment: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  getPreviousMap: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
  onOpenTasks: PropTypes.func.isRequired,
  stateRef: PropTypes.object.isRequired,
  user: PropTypes.object,
};

export default AssignmentDetail;
