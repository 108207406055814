import React, { memo } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { makeStyles } from '@material-ui/styles';

import RoomIcon from '@material-ui/icons/Room';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

import { Trigger } from '@geomagic/core';
import { getEntityType } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';

import { DEFAULT_TRIGGER_ICON_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';

import getFeatures from './getFeatures';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: 'flex',
  },
  trigger: {
    height: MOBILE_TRIGGER_SIZE,
    width: MOBILE_TRIGGER_SIZE,
  },
}));

const DispatchListActions = props => {
  // Einige Props via "additionalContextProps" in Dispatchlist.js übergeben
  const { areMapActionsDisabled, data, entityClasses, onCloseSwipeableArea, onRemoveDraft, onShowOnMap } = props;

  const entity = data?.getPatchedEntity();

  const features = !areMapActionsDisabled && getFeatures([data], entityClasses);
  const isDraft = !!data?.draft;

  const entityType = getEntityType(entityClasses, entity?.className, entity?.entityType?.id);
  const hasFeatures = features?.length > 0;
  const label = data?.draft ? entityType?.name : entity?.displayName;

  const classes = useStyles();

  /**
   *  EVENT HANDLER
   */

  const handleRemoveDraft = event => {
    event.stopPropagation();
    onRemoveDraft && onRemoveDraft(label, data);
  };

  const handleShowOnMap = event => {
    event.stopPropagation();
    onCloseSwipeableArea && onCloseSwipeableArea();
    onShowOnMap && onShowOnMap(features, entity.id);
  };

  /**
   *  ACTION MENU ITEMS
   */

  return (
    <div className={classes.root}>
      {hasFeatures && (
        <Tooltip title={i18n.t('tooltip.showOnMap')}>
          <Trigger
            {...DEFAULT_TRIGGER_ICON_PROPS}
            className={classes.trigger}
            icon={<RoomIcon />}
            onClick={handleShowOnMap}
          />
        </Tooltip>
      )}
      {isDraft && (
        <Tooltip title={i18n.t('tooltip.delete')}>
          <Trigger
            {...DEFAULT_TRIGGER_ICON_PROPS}
            className={classes.trigger}
            icon={<DeleteIcon />}
            onClick={handleRemoveDraft}
          />
        </Tooltip>
      )}
    </div>
  );
};

DispatchListActions.propTypes = {
  areMapActionsDisabled: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  onCloseSwipeableArea: PropTypes.func,
  onRemoveDraft: PropTypes.func,
  onShowOnMap: PropTypes.func,
};

export default memo(DispatchListActions, (prev, next) => deepEqual(prev, next));
