import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';

import ListItemText from '@material-ui/core/ListItemText';

import { getAttributeValueByCode } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import { DATE_DISPLAY_FORMAT } from '@consts';

import { PROPERTY_NAME_DUEDATE } from './consts';

const useStyles = makeStyles(({ breakpoints, palette, spacing, transitions, typography }) => ({
  root: {},
}));

const AssignmentListText = props => {
  const { data, entityClasses } = props;
  const entity = data.getPatchedEntity();
  const { value: dueDate } = getAttributeValueByCode(entityClasses, entity, PROPERTY_NAME_DUEDATE) || {};

  const classes = useStyles();

  return (
    <ListItemText
      className={classes.root}
      primary={entity?.displayName}
      secondary={
        dueDate
          ? i18n.t('label.dueDateListItem', {
              variables: {
                date: dayjs(dueDate).format(DATE_DISPLAY_FORMAT),
              },
            })
          : ''
      }
      primaryTypographyProps={{ noWrap: true, variant: 'body1' }}
      secondaryTypographyProps={{ noWrap: true, variant: 'body2' }}
    />
  );
};

AssignmentListText.propTypes = {
  data: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
};

export default AssignmentListText;
