import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import pointer from 'json-pointer';
import { useSnackbar } from 'notistack';
import { useRxDB, useRxDocument } from 'rxdb-hooks';
import { makeStyles } from '@material-ui/styles';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import MapIcon from '@material-ui/icons/Map';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { hideModal, Trigger } from '@geomagic/core';
import { getEntityType } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';

import { PRIMARY_TRIGGER_PROPS } from '@consts';
import { ENTITY_SELECTOR_KEY } from '@database/consts';
import getPatch from '@database/getPatch';
import useShowPrompt from '@utils/useShowPrompt';

import DispatchDocuments from './DispatchDocuments';
import DispatchForm from './DispatchForm';
import DispatchMap from './DispatchMap';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import getTasksAmount from '../Tasks/getTasksAmount';
import Checklist from '../Checklist';
import Journal from '../Journal';
import Tasks from '../Tasks';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  paper: {},
  tabs: {
    background: palette.background.default,
    borderBottom: `1px solid ${palette.divider}`,
  },
  tab: {
    textTransform: 'capitalize',
    [breakpoints.down('sm')]: {
      minWidth: '0px',
    },
  },
  badge: {
    backgroundColor: palette.secondary.main,
  },
  content: {
    background: palette.background.paper,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  tasks: {
    overflow: 'auto',
    padding: spacing(0.5, 1),
  },
  triggerClose: {
    marginLeft: spacing(),
  },
}));

const getClosedElementPatch = ({ entity, path, updateId, update }) => {
  const items = pointer.get(entity, path);
  const updatedItems = items.map((item) => (item.id === updateId ? { ...item, ...update } : item));

  return {
    op: 'replace',
    path,
    value: updatedItems,
  };
};

const DispatchWizard = (props) => {
  const {
    className,
    entityClass,
    entityClasses,
    entityId,
    handleClose,
    isDocumentsHidden,
    isMobile,
    isOnline,
    mapProps,
    onSyncEntity,
    onUpdateEntity,
    previousMap,
    setDialogTitle,
    user,
  } = props;

  const database = useRxDB();
  const { result: dispatch } = useRxDocument('dispatches', entityId, {
    idAttribute: ENTITY_SELECTOR_KEY,
  });

  const entity = dispatch?.getPatchedEntity() || {};
  const entityType = entity?.entityType && getEntityType(entityClasses, entity?.className, entity?.entityType?.id);
  const entityTypeName = entityType?.name;
  const { documents = [] } = entity;

  const draftRef = useRef();
  const showPrompt = useShowPrompt();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const entityTypes = entityClass?.entityTypes;
  const draft = dispatch?.draft;
  const isDraft = !!draft;
  const isDraftClosed = !!draft?.closed;

  const counterDocuments = documents?.length || 0;
  const counterChecklist = entity?.checklistItems?.length || 0;
  const counterTasks = getTasksAmount(entity?.processInstances) || 0;
  const counterJournal = entity?.journal?.length || 0;
  const areChecklistItemsChecked = entity?.checklistItems?.every((item) => item.checked);

  const getTabs = () => {
    return [
      {
        id: 'form',
        count: null,
        name: i18n.t('dispatch.label.tabGeneral'),
        component: DispatchForm,
        icon: <FormatListBulletedIcon />,
      },
      {
        id: 'map',
        count: null,
        name: i18n.t('dispatch.label.tabMap'),
        component: DispatchMap,
        icon: <MapIcon />,
      },
      ...(isDocumentsHidden
        ? []
        : [
            {
              id: 'documents',
              count: counterDocuments,
              name: i18n.t('dispatch.label.tabDocuments', {
                variables: {
                  count: String(counterDocuments),
                },
              }),
              component: DispatchDocuments,
              icon: <AttachFileIcon />,
            },
          ]),
      ...(isDraft
        ? []
        : [
            {
              id: 'tasks',
              count: counterTasks,
              name: i18n.t('process.label.menuItem', {
                variables: {
                  amount: String(counterTasks),
                },
              }),
              component: () => (
                <div className={classes.tasks}>
                  <Tasks
                    closeableErrorText={i18n.t('dispatch.description.checklistItemsNotChecked')}
                    data={dispatch}
                    entityClasses={entityClasses}
                    isCloseable={areChecklistItemsChecked && isOnline}
                    isMobile={isMobile}
                    isOnline={isOnline}
                    onSyncEntity={onSyncEntity}
                    onUpdateEntity={onUpdateEntity}
                    user={user}
                  />
                </div>
              ),
              icon: <AccountCircleIcon />,
            },
          ]),
      {
        id: 'checklist',
        count: counterChecklist,
        name: i18n.t('checklist.label.menuItem', {
          variables: {
            amount: String(counterChecklist),
          },
        }),
        component: () => <Checklist data={dispatch} entityClasses={entityClasses} />,
        icon: <AssignmentTurnedInIcon />,
      },
      ...(isDraft
        ? []
        : [
            {
              id: 'journal',
              count: counterJournal,
              name: i18n.t('journal.label', {
                variables: {
                  amount: String(counterJournal),
                },
              }),
              component: () => <Journal data={dispatch} user={user} />,
              icon: <MenuBookIcon />,
            },
          ]),
    ];
  };

  const tabs = getTabs();
  const TabComponent = tabs[activeStep].component || null;

  /**
   *  GENERAL PROPS
   */

  const triggerProps = {
    size: 'medium',
  };

  /**
   *  EVENT HANDLER
   */

  const handleChangeTab = (event, newValue) => {
    const execute = () => setActiveStep(newValue);

    handleFormValidation(execute);
  };

  const handleFormValidation = (execute) => {
    const onValidateForm = draftRef?.current?.onValidateForm;
    const formStepIndex = tabs.findIndex(({ id }) => id === 'form');

    if (!isDraftClosed && onValidateForm && activeStep === formStepIndex) {
      onValidateForm()
        .then(() => {
          execute();
        })
        .catch((error) => console.log(error));
    } else {
      execute();
    }
  };

  const handlePromptCloseDraft = () => {
    const execute = () => {
      // Timeout is needed to notice type changes.
      setTimeout(() => {
        showPrompt({
          title: i18n.t('dispatch.dialog.closeDraft.title'),
          content: i18n.t('dispatch.dialog.closeDraft.content'),
          onOk: () => handleCloseDraft(dispatch),
        });
      }, 50);
    };

    handleFormValidation(execute);
  };

  const handleCloseDraft = async () => {
    const { uuid, relations } = dispatch;
    const update = { closed: true };

    if (relations) {
      const assignments = relations.filter(({ type }) => type === 'Assignment');

      for (let i = 0; i < assignments.length; i++) {
        const { id, path } = assignments[i];

        const collection = database.assignments;
        const selector = { [ENTITY_SELECTOR_KEY]: id };
        const assignmentDoc = await collection.findOne({ selector }).exec();
        const jsonPatch = assignmentDoc?.jsonPatch;
        const patchedEntity = assignmentDoc.getPatchedEntity();

        const newPatch = getClosedElementPatch({ entity: patchedEntity, path, updateId: uuid, update });

        await assignmentDoc.atomicUpdate((oldData) => {
          oldData.jsonPatch = getPatch(jsonPatch, newPatch);
          return oldData;
        });
      }
    }

    await dispatch.atomicUpdate((oldData) => {
      oldData.draft = { ...oldData.draft, closed: true };
      return oldData;
    });

    hideModal();
    handleClose();

    enqueueSnackbar(i18n.t('dispatch.notification.closedDraft'), {
      key: 'closedDraft',
      preventDuplicate: true,
      variant: 'success',
    });
  };

  const handleUpdateDraft = async (newPatch) => {
    const jsonPatch = dispatch?.jsonPatch;

    await dispatch.atomicUpdate((oldData) => {
      oldData.jsonPatch = getPatch(jsonPatch, newPatch);
      return oldData;
    });
  };

  /**
   * EFFECTS
   */

  useEffect(() => {
    if (setDialogTitle && entityTypeName) {
      setDialogTitle(entityTypeName);
    }
  }, [entityTypeName, setDialogTitle]);

  /**
   *  COMPONENTS
   */

  const CloseComponent = (
    <div>
      {isDraft && !isDraftClosed && (
        <Trigger
          className={classes.triggerClose}
          onClick={handlePromptCloseDraft}
          {...PRIMARY_TRIGGER_PROPS}
          {...triggerProps}
        >
          {i18n.t('dispatch.button.closeDraft')}
        </Trigger>
      )}
    </div>
  );

  const getStepComponent = () => {
    return (
      <TabComponent
        CloseComponent={CloseComponent}
        doc={dispatch}
        draftRef={draftRef}
        entityClass={entityClass}
        entityClasses={entityClasses}
        entityTypes={entityTypes}
        isDraft={isDraft}
        isMobile={isMobile}
        mapProps={mapProps}
        onClose={handleCloseDraft}
        onChange={handleUpdateDraft}
        step={tabs[activeStep]}
        triggerProps={triggerProps}
        previousMap={previousMap}
      />
    );
  };

  // Breakpoint "isMobile" aus App ist hier zu viel.
  // Warnung: classes.tab oben nutzt ebenfalls einen sm-Breakpoint,
  // damit z. B. 6 Tabs auch auf kleineren Geräten noch touchbar sind.
  // MuiV4 hat keine Variante, bei der die Tabs fullWidth und ansehnlich scrollbar sind.
  const isMobileSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <div className={classNames(classes.root, className)}>
      <Paper className={classes.paper} square>
        <Tabs
          centered
          className={classes.tabs}
          indicatorColor="primary"
          onChange={handleChangeTab}
          scrollButtons="off"
          textColor="primary"
          value={activeStep}
          variant={isMobileSM ? 'fullWidth' : 'standard'}
        >
          {tabs.map(({ id, name, icon, count }) => (
            <Tab
              key={id}
              className={classes.tab}
              label={!isMobileSM && name}
              icon={
                isMobileSM && count ? (
                  <Badge classes={{ badge: classes.badge }} color="secondary" overlap="circular" variant="dot">
                    {icon}
                  </Badge>
                ) : (
                  icon
                )
              }
            />
          ))}
        </Tabs>
      </Paper>
      <div className={classes.content}>{dispatch && entityTypes && getStepComponent()}</div>
    </div>
  );
};

DispatchWizard.propTypes = {
  className: PropTypes.string,
  entityClass: PropTypes.object,
  entityClasses: PropTypes.array,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClose: PropTypes.func.isRequired,
  isDocumentsHidden: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
  onSyncEntity: PropTypes.func,
  onUpdateEntity: PropTypes.func,
  previousMap: PropTypes.object,
  setDialogTitle: PropTypes.func,
  user: PropTypes.object.isRequired,
};

export default DispatchWizard;
