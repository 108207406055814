import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: spacing(1, 0),
    borderBottom: `1px dotted ${palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: spacing(),
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5),
    width: '100%',
    overflow: 'hidden',
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    margin: spacing(1, 0),
  },
  title: {
    fontWeight: 500,
  },
  subtitle: {},
  item: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    minHeight: 60,
    overflow: 'hidden',
    [breakpoints.down('md')]: {
      margin: 0,
    },
  },
  lineBreak: {
    flexBasis: '100%',
    width: 0,
    height: 0,
    overflow: 'hidden',
    [breakpoints.down('md')]: {
      height: 0,
    },
  },
}));

const FormElementRow = props => {
  const classes = useStyles(props);
  const { children, context, data, infoTrigger } = props;
  const { isEditMode } = context;
  const { adjacentElements, description, name } = data;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant="body1" noWrap>
            {name}
          </Typography>
          {infoTrigger}
        </div>
        {description && !infoTrigger && (
          <Typography className={classes.subtitle} variant="body2" color="textSecondary" noWrap>
            {description}
          </Typography>
        )}
      </div>
      <div className={classNames(classes.item, { [classes.edit]: isEditMode })}>
        {React.Children.map(children, (child, childIndex) => (
          <Fragment>
            {React.cloneElement(child, {
              parentName: name,
              isDefectStatusSelected: children?.some(({ props }) => props?.data?.statusValue?.isDefectStatus),
            })}
            {(childIndex + 1) % adjacentElements === 0 && childIndex < children.length - 1 && (
              <div className={classNames(classes.lineBreak, { [classes.edit]: isEditMode })} />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

FormElementRow.propTypes = {
  children: PropTypes.node,
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  infoTrigger: PropTypes.node,
};

export default FormElementRow;
