import { compareByReference, getAttributeTypesByClassAndType } from '@geomagic/geonam';
import getFormattedAttributeValue from './getFormattedAttributeValue';

/**
 * Taken from @geomagic/geonam/src/util.js
 * but with read-only fix (line 28)
 *
 * @method
 * @param {array} entityClasses
 * @param {object} entity
 * @param {string} code
 * @return {string}
 */

const getAttributeValueByCode = (entityClasses, entity, code) => {
  const attributeTypeByCode = getAttributeTypesByClassAndType(
    entityClasses,
    entity.className,
    entity.entityType && entity.entityType.id
  ).find(attributeType => attributeType.code === code);

  const attributeValueTemp =
    attributeTypeByCode &&
    entity.attributeValues.find(({ attributeType: attributeValueAttributeType }) =>
      compareByReference(attributeTypeByCode, attributeValueAttributeType)
    );

  const attributeValue = attributeValueTemp && { ...attributeValueTemp };

  if (attributeValue) {
    attributeValue.attributeType = attributeTypeByCode;
    attributeValue.formattedValue = getFormattedAttributeValue(attributeTypeByCode, attributeValue.value);
  }

  return attributeValue;
};

export default getAttributeValueByCode;
