/**
 * Avatar component to claim the first task of an assignment.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';

import { i18n } from '@geomagic/i18n';
import useIsMountedRef from '@geomagic/nam-react-core/utils/useIsMountedRef';
import { Trigger } from '@geomagic/core';

import MutationClaimUnassignedTask from '@graphql/mutations/MutationClaimUnassignedTask';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';
import { DEFAULT_TRIGGER_ICON_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';

import getCurrentTask from './getCurrentTask';

const useStyles = makeStyles(({ breakpoints }) => ({
  trigger: {
    height: MOBILE_TRIGGER_SIZE,
    width: MOBILE_TRIGGER_SIZE,
  },
}));

const ClaimAssignment = props => {
  const { data, processInstances, onClaimAssignment, onUpdateAssignment } = props;
  const [isLoading, setLoading] = useState(false);
  const task = getCurrentTask(processInstances);
  const taskId = task?.id;

  const [claimUnassignedTask] = useMutation(MutationClaimUnassignedTask);
  const enqueueLoadingSnackbar = useLoadingSnackbar();
  const isMountedRef = useIsMountedRef();
  const classes = useStyles();

  /**
   *  EVENT HANDLER
   */

  const updateEntity = async () => {
    await onUpdateAssignment(data);
  };

  const handleClaimTask = async () => {
    setLoading(true);

    const execute = () =>
      claimUnassignedTask({ variables: { taskId } })
        .then(updateEntity)
        .then(() => {
          onClaimAssignment && onClaimAssignment(data);
        })
        .catch(error => {
          updateEntity();
        })
        .finally(() => isMountedRef.current && setLoading(false));

    await enqueueLoadingSnackbar({
      loadingText: i18n.t('process.notification.claimTask'),
      finishedText: i18n.t('process.notification.taskClaimed'),
      finishedVariant: 'success',
      func: execute,
    });
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Tooltip title={i18n.t('tooltip.takeOver')}>
          <Trigger
            {...DEFAULT_TRIGGER_ICON_PROPS}
            className={classes.trigger}
            icon={<AssignmentReturnIcon />}
            onClick={handleClaimTask}
          />
        </Tooltip>
      )}
    </>
  );
};

ClaimAssignment.propTypes = {
  data: PropTypes.object.isRequired,
  onClaimAssignment: PropTypes.func,
  onUpdateAssignment: PropTypes.func.isRequired,
  processInstances: PropTypes.array,
};

export default ClaimAssignment;
