/**
 * A component to pick an image.
 *
 * On mobile there will be a dialog to select between new image and and from gallery.
 */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import AddPictureIcon from '@material-ui/icons/AddAPhoto';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import InlineMenu from '@geomagic/nam-react-core/components/InlineMenu';

const useStyles = makeStyles(() => ({
  root: {},
  fileInput: {
    display: 'none',
  },
  listItemIcon: {
    minWidth: 34,
  },
}));

const ImagePicker = props => {
  const { className, onChange, triggerProps } = props;

  const [anchorEl, setAnchorEl] = useState();
  const classes = useStyles();
  const fileInputRefCapture = useRef();
  const fileInputRefGallery = useRef();

  const inputProps = {
    accept: 'image/*',
    className: classes.fileInput,
    onChange,
    type: 'file',
  };

  /**
   *  EVENT HANDLER
   */

  const handleClosePopper = () => {
    setAnchorEl(null);
  };

  const handleTriggerClick = event => setAnchorEl(event.currentTarget);

  return (
    <>
      <Trigger
        className={classNames(classes.root, className)}
        icon={<AddPictureIcon />}
        onClick={handleTriggerClick}
        variant="icon"
        {...triggerProps}
      />
      <InlineMenu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClosePopper}>
        <MenuItem onClick={() => fileInputRefCapture.current.click()}>
          <ListItemIcon className={classes.listItemIcon}>
            <PhotoCameraIcon />
          </ListItemIcon>
          <ListItemText primary={i18n.t('button.capturePicture')} />
        </MenuItem>
        <MenuItem onClick={() => fileInputRefGallery.current.click()}>
          <ListItemIcon className={classes.listItemIcon}>
            <PhotoLibraryIcon />
          </ListItemIcon>
          <ListItemText primary={i18n.t('button.takeFromGallery')} />
        </MenuItem>
      </InlineMenu>
      <input {...inputProps} capture="environment" ref={fileInputRefCapture} />
      <input {...inputProps} ref={fileInputRefGallery} />
    </>
  );
};

ImagePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  triggerProps: PropTypes.object,
};

export default ImagePicker;
