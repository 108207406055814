import React from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Link from '@material-ui/core/Link';
import { TIMESTAMP_DISPLAY_FORMAT } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import { DATE_DISPLAY_FORMAT } from '@consts';

import convertDateFormat from './convertDateFormat';
import getMomentLocale from './getMomentLocale';
import getNumberFormatObject from './getNumberFormatObject';
import getRawAttributeValue from './getRawAttributeValue';
import getTypedAttributeValue from './getTypedAttributeValue';
import FractionFormat from '../components/FractionFormat';

/**
 * Get a formatted value by specific GeoNAM attribute type
 * @param {Object} attributeType - GeoNAM attribute type
 * @param {string} value
 * @returns {*}
 */

const getFormattedAttributeValue = (attributeType, value) => {
  let format, locale;
  const { allowedValues, options } = attributeType;
  const getValueObject = () => {
    switch (attributeType.dataType) {
      case 'BOOLEAN':
        const { trueLabel, falseLabel } = options;
        return value === 'true' || (typeof value === 'boolean' && value === true)
          ? trueLabel
            ? trueLabel
            : i18n.t('default.yes')
          : value === 'false' || (typeof value === 'boolean' && value === false)
          ? falseLabel
            ? falseLabel
            : i18n.t('default.no')
          : '';
      case 'FRACTION':
        return value != null ? (
          <FractionFormat value={Number(value)} {...getNumberFormatObject(attributeType)} />
        ) : null;
      case 'INT':
      case 'FLOAT':
      case 'CURRENCY':
        return value != null ? (
          <NumberFormat value={Number(value)} displayType={'text'} {...getNumberFormatObject(attributeType)} />
        ) : null;
      case 'DATE':
        locale = getMomentLocale();
        format = options && options.pattern ? convertDateFormat(options.pattern) : locale ? 'L' : DATE_DISPLAY_FORMAT;
        return value ? moment(value).format(format) : null;
      case 'TIMESTAMP':
        locale = getMomentLocale();
        format =
          options && options.pattern ? convertDateFormat(options.pattern) : locale ? 'L LTS' : TIMESTAMP_DISPLAY_FORMAT;
        return value ? moment(value).format(format) : null;
      case 'LINK':
        return (
          <Link target="_blank" rel="noreferrer" href={value}>
            {value}
          </Link>
        );
      case 'EMAIL':
        return <Link href={'mailto:' + value}>{value}</Link>;
      case 'STRING':
        const { link, email } = options;
        if (link) {
          return (
            <Link target="_blank" rel="noreferrer" href={value}>
              {value}
            </Link>
          );
        } else if (email) {
          return <Link href={'mailto:' + value}>{value}</Link>;
        } else {
          return getRawAttributeValue(attributeType, value);
        }
      default:
        return getRawAttributeValue(attributeType, value);
    }
  };

  const isEnumeration = allowedValues && !!allowedValues.length;
  if (isEnumeration && value) {
    const selectedAllowedValue = allowedValues.find(
      allowedValue =>
        getTypedAttributeValue(attributeType, allowedValue.value) === getTypedAttributeValue(attributeType, value)
    );
    if (selectedAllowedValue && selectedAllowedValue.name) {
      return <span>{selectedAllowedValue.name}</span>;
    }
  }
  return getValueObject();
};

export default getFormattedAttributeValue;
