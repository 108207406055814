import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

import useServiceWorker from '@serviceWorker/useServiceWorker';
import { STANDARD_ERROR_KEY } from '@consts';

const useStyles = makeStyles(({ spacing }) => {
  return {
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    message: {},
    trigger: {
      marginLeft: spacing(),
    },
  };
});

/**
 * Test whether a error is a fetch error. Known error messages.
 * 'Type Error: Failed to fetch',
 * 'Failed to load',
 * 'NetworkError when attempting to fetch resource',
 * 'Type Error: Load failed',
 * @param {array} errors
 * @returns
 */
const isFetchError = errors => {
  const fetchErrors = ['fetch', 'load'];
  const regex = new RegExp(fetchErrors.join('|'), 'i');
  return errors.some(error => regex.test(error.message));
};

/**
 * Test whether a error is a session expired error. Known error messages.
 * 'Decode argument cannot be null',
 * @param {array} errors
 * @returns
 */
const isSessionExpired = errors => {
  const sessionExpiredErrors = ['Decode argument cannot be null'];
  const regex = new RegExp(sessionExpiredErrors.join('|'), 'i');
  return errors.some(error => regex.test(error.message));
};

const UPDATE_AVAILABLE_KEY = 'updateAvailable';
const SESSION_EXPIRED_KEY = 'sessionExpired';

const SnackbarContentComponent = props => {
  const { message, onClick } = props;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.message}>{message}</div>
      {onClick && (
        <Trigger className={classes.trigger} color="inherit" onClick={onClick} size="small" variant="outlined">
          {i18n.t('button.reload')}
        </Trigger>
      )}
    </div>
  );
};

SnackbarContentComponent.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func,
};

const useErrorHandler = props => {
  const { errors = [], isOnline, setErrors } = props;
  const { isUpdateAvailable, reloadServiceWorker, updateAssets } = useServiceWorker();
  const { enqueueSnackbar } = useSnackbar();
  console.log(errors);

  const prevIsOnline = useRef(true);

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (isUpdateAvailable) {
      const SnackbarContent = (
        <SnackbarContentComponent message={i18n.t('label.updateAvailable')} onClick={updateAssets} />
      );

      enqueueSnackbar(SnackbarContent, {
        key: UPDATE_AVAILABLE_KEY,
        persist: true,
        preventDuplicate: true,
        variant: 'info',
      });
    }
  }, [isUpdateAvailable, enqueueSnackbar, updateAssets]);

  useEffect(() => {
    if (!prevIsOnline.current) {
      if (isOnline) {
        setErrors([]);
      }
    } else {
      const hasError = errors.length > 0;
      if (hasError && isOnline) {
        const onClose = () => setErrors([]);
        if (isFetchError(errors)) {
          const SnackbarContent = (
            <SnackbarContentComponent message={i18n.t('label.fetchError')} onClick={reloadServiceWorker} />
          );

          enqueueSnackbar(SnackbarContent, {
            key: STANDARD_ERROR_KEY,
            persist: true,
            preventDuplicate: true,
            onClose,
            variant: 'error',
          });
        } else if (isSessionExpired(errors)) {
          const SnackbarContent = <SnackbarContentComponent message={i18n.t('label.sessionExpired')} />;

          enqueueSnackbar(SnackbarContent, {
            key: SESSION_EXPIRED_KEY,
            preventDuplicate: true,
            onClose,
            variant: 'warning',
          });
        } else {
          const errorMessage = errors[0].message;
          const SnackbarContent = (
            <SnackbarContentComponent
              message={errorMessage || i18n.t('label.unknownError')}
              onClick={errorMessage ? null : reloadServiceWorker}
            />
          );

          enqueueSnackbar(SnackbarContent, {
            key: STANDARD_ERROR_KEY,
            persist: true,
            preventDuplicate: true,
            onClose,
            variant: 'error',
          });
        }
      }
    }
    prevIsOnline.current = isOnline;
  }, [errors, enqueueSnackbar, reloadServiceWorker, isOnline, setErrors]);
};

useErrorHandler.propTypes = {
  errors: PropTypes.array,
  isOnline: PropTypes.bool,
  setErrors: PropTypes.func.isRequired,
};

export default useErrorHandler;
