import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';

import ListItem from '@components/ListItem';
import ListSkeleton from './ListSkeleton';

const useStyles = makeStyles(({ palette, spacing, typography }) => {
  return {
    root: {
      backgroundColor: palette.background.paper,
      flex: 1,
      overflow: 'auto',
      padding: 0,
      position: 'relative',
    },
    li: {
      listStyle: 'none',
    },
    ul: {
      margin: 0,
      padding: 0,
      backgroundColor: palette.background.paper,
    },
  };
});

const ListComponent = props => {
  const {
    activeId,
    additionalProps,
    className,
    items = [],
    idKey,
    loading,
    PlaceholderComponent,
    ActionsComponent,
    AvatarComponent,
    TextComponent,
    onClick,
  } = props;

  const listItemRefs = useRef({});
  const classes = useStyles(props);

  /**
   *  COMPONENTS
   */

  const list =
    items.length > 0
      ? items.map(item => {
          const itemId = idKey ? get(item, idKey) : item.id;

          return (
            <ListItem
              ActionsComponent={ActionsComponent}
              activeId={activeId}
              additionalProps={additionalProps}
              AvatarComponent={AvatarComponent}
              key={itemId}
              listItemRefs={listItemRefs}
              item={item}
              itemId={itemId}
              onClick={onClick}
              TextComponent={TextComponent}
            />
          );
        })
      : PlaceholderComponent;

  return (
    <List className={classNames(classes.root, className)} subheader={<li />}>
      {loading ? <ListSkeleton /> : list}
    </List>
  );
};

ListComponent.propTypes = {
  ActionsComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  activeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  AvatarComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  className: PropTypes.string,
  items: PropTypes.array,
  idKey: PropTypes.string,
  loading: PropTypes.bool,
  PlaceholderComponent: PropTypes.node,
  TextComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default ListComponent;
