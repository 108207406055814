/**
 * A list for creating/editing a checklist.
 *
 * Currently only displayed for dispatches.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListIcon from '@material-ui/icons/List';

import { i18n } from '@geomagic/i18n';
import { getEntityClass, isAllowedUPDATE } from '@geomagic/geonam';

import Placeholder from '@components/Placeholder';
import getPatch from '@database/getPatch';

import AddChecklistItem from './AddChecklistItem';
import ChecklistItem from './ChecklistItem';

const ASSIGNMENT_CLASSNAME = 'Assignment';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
}));

const getEntry = ({
  assignment = null,
  assignmentTypeCatId = null,
  checked = false,
  name,
  id = uuidv4(),
  seqNumber = null,
}) => {
  return { assignment, assignmentTypeCatId, checked, id, name, seqNumber };
};

const Checklist = props => {
  const { data, entityClasses } = props;
  const { draft } = data;
  const entity = data?.getPatchedEntity();
  const { checklistItems = [] } = entity;

  const classes = useStyles(props);

  const [, setChecklistUpdated] = useState(false);
  const [editItem, setEditItem] = useState();

  const isAllowedUpdateEntity = isAllowedUPDATE(entity) || !!draft;

  const assignmentClass = getEntityClass(entityClasses, ASSIGNMENT_CLASSNAME);
  const { entityTypes = [] } = assignmentClass;
  const assignmentTypesSelectOptions = [];

  entityTypes.forEach(entityType => {
    if (entityType.creatable) {
      assignmentTypesSelectOptions.push({
        label: entityType.name,
        id: entityType.id,
      });
    }
  });

  const isClosed = entity?.processingStatus?.includes('CLOSED');
  const showCreateChecklistItem = isAllowedUpdateEntity && !isClosed;

  /**
   *  EVENT HANDLER
   */

  const handleChecklistUpdate = async newPatch => {
    const jsonPatch = data?.jsonPatch;
    await data.atomicUpdate(oldData => {
      oldData.jsonPatch = getPatch(jsonPatch, newPatch);
      return oldData;
    });
    setChecklistUpdated(prevChecklistUpdated => !prevChecklistUpdated);
  };

  const handleAddNewItem = name => {
    const newChecklistItems = [...checklistItems, getEntry({ name })];
    const newPatch = {
      op: 'replace',
      path: `/checklistItems`,
      value: newChecklistItems,
    };
    handleChecklistUpdate(newPatch);
  };

  const handleToogleEditItem = (event, newEditItem) => {
    setEditItem(newEditItem || null);
  };

  const handleFinishEdit = () => {
    if (editItem.name.length > 0) {
      const newChecklistItems = checklistItems.map(item => (item.id === editItem.id ? getEntry(editItem) : item));
      const newPatch = {
        op: 'replace',
        path: `/checklistItems`,
        value: newChecklistItems,
      };
      handleChecklistUpdate(newPatch).then(() => setEditItem(null));
    }
  };

  const handleToogleCheck = (newChecked, newCheckedId) => {
    const newChecklistItems = checklistItems.map(item =>
      item.id === newCheckedId ? getEntry({ ...item, checked: newChecked }) : item
    );

    const newPatch = {
      op: 'replace',
      path: `/checklistItems`,
      value: newChecklistItems,
    };
    handleChecklistUpdate(newPatch).then(() => setEditItem(null));
  };

  const handleDeleteItem = deleteId => {
    const newChecklistItems = checklistItems.filter(item => item.id !== deleteId);
    const newPatch = {
      op: 'replace',
      path: `/checklistItems`,
      value: newChecklistItems,
    };
    handleChecklistUpdate(newPatch);
  };

  return (
    <List className={classes.root} dense>
      {showCreateChecklistItem && <AddChecklistItem handleAddNewItem={handleAddNewItem} />}
      {checklistItems.length === 0 ? (
        <Placeholder
          icon={<ListIcon />}
          title={i18n.t('placeholder.noEntries.title')}
          subtitle={i18n.t('placeholder.noEntries.subtitle')}
        />
      ) : (
        checklistItems.map((item, index) => (
          <ChecklistItem
            assignmentEntityTypes={entityTypes}
            className={classes.checklistItem}
            editItem={editItem}
            entityTypesSelectOptions={assignmentTypesSelectOptions}
            handleFinishEdit={handleFinishEdit}
            handleDelete={handleDeleteItem}
            handleToogleCheck={handleToogleCheck}
            handleToogleEditItem={handleToogleEditItem}
            isAllowedUpdateEntity={isAllowedUpdateEntity}
            isEditMode={editItem && editItem.id === item.id}
            isLastItem={index === checklistItems.length - 1}
            item={item}
            key={item.id}
            setEditItem={setEditItem}
          />
        ))
      )}
    </List>
  );
};

Checklist.propTypes = {
  data: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
};

export default Checklist;
