/**
 * Delegates a task to a candidate for the current task.
 */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { useQuery } from '@apollo/client';
import DialogContentText from '@material-ui/core/DialogContentText';

import { AutoForm, submitForm } from '@geomagic/forms';
import { QueryLoadingOrError, Trigger } from '@geomagic/geonam';
import { QueryCandidates } from '@geomagic/geonam-graphql';
import { i18n } from '@geomagic/i18n';
import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';

import { DEFAULT_TEXT_FIELD_PROPS, PRIMARY_TRIGGER_PROPS, SECONDARY_TRIGGER_PROPS } from '@consts';
import QueryTeamsWithAccess from '@graphql/queries/QueryTeamsWithAccess';

import SelectAssignee from './SelectAssignee';
import SelectTeam from './SelectTeam';

const DESCRIPTION_FIELD_ROWS = 5;

/**
 * Returns the Auto Form props.
 *
 * @param {array} candidates
 * @returns {object}
 */
const getAutoFormProps = (candidates, teams) => {
  const areTeamsAvailable = teams.length > 0;
  return {
    schema: {
      type: 'object',
      properties: {
        ...(areTeamsAvailable
          ? {
              team: {
                type: 'object',
                title: i18n.t('default.team'),
                enum: teams,
              },
            }
          : {}),
        assignee: {
          type: 'object',
          title: i18n.t('default.user'),
          enum: candidates,
        },
        message: {
          type: 'string',
          title: i18n.t('default.journalEntry'),
        },
      },
    },
    ui: {
      ...(areTeamsAvailable
        ? {
            team: {
              CustomFormField: SelectTeam,
              teamOptions: teams,
            },
          }
        : {}),
      assignee: {
        CustomFormField: SelectAssignee,
        assigneeOptions: candidates,
      },
      message: {
        rows: DESCRIPTION_FIELD_ROWS,
        multiline: true,
        ...DEFAULT_TEXT_FIELD_PROPS,
      },
    },
  };
};

const DelegateTaskDialog = props => {
  const { entityReference, isLoading, isMobile, onClose, onSubmit, open, task } = props;

  const { assignee: assigneeLogin, assignedTeam, id: taskId, name: taskName } = task;
  const formId = 'delegateTask' + taskId;

  /*
   * GRAPHQL CONFIG
   */

  const queryConfig = {
    skip: !open,
    variables: {
      taskIds: [taskId],
    },
  };

  const teamsQueryConfig = {
    fetchPolicy: 'network-only',
    variables: { entityReference },
  };

  /*
   * GRAPHQL QUERIES
   */

  const { data: dataCandidates = {}, loading: isLoadingUsers } = useQuery(QueryCandidates, queryConfig);

  const { data: teamData = {}, loading: isLoadingTeams } = useQuery(QueryTeamsWithAccess, teamsQueryConfig);

  const { candidates = [] } = dataCandidates;

  const { teamsWithAccess = [] } = teamData;

  const areCandidatesAvailable = candidates.length > 0;

  const isLoadingQueries = isLoadingUsers || isLoadingTeams;

  const candidatesRef = useRef(candidates);
  const teamsRef = useRef(teamsWithAccess);
  const [autoFormProps, setAutoFormProps] = useState(() =>
    areCandidatesAvailable ? getAutoFormProps(candidates) : null
  );

  const currentAssignee = assigneeLogin
    ? candidates.find(candidate => candidate.loginName === assigneeLogin)
    : undefined;
  const currentTeam = assignedTeam ? teamsWithAccess.find(team => team.id === assignedTeam.id) : undefined;
  const defaultValues = { assignee: currentAssignee, team: currentTeam };

  /**
   *  EFFECTS
   */

  useEffect(() => {
    const usersChanged = !deepEqual(candidatesRef.current, candidates);
    const teamsChanged = !deepEqual(teamsRef.current, teamsWithAccess);

    if (usersChanged || teamsChanged) {
      if (usersChanged) {
        candidatesRef.current = candidates;
      }
      if (teamsChanged) {
        teamsRef.current = teamsWithAccess;
      }
      if (areCandidatesAvailable) {
        setAutoFormProps(getAutoFormProps(candidates, teamsWithAccess));
      }
    }
  }, [candidates, areCandidatesAvailable, teamsWithAccess]);

  /**
   *  EVENT HANDLER
   */

  const handleSubmitForm = () => {
    submitForm(formId);
  };

  /**
   *  COMPONENTS
   */

  const ContentComponent = isLoadingQueries ? (
    <QueryLoadingOrError loading={isLoadingQueries} />
  ) : areCandidatesAvailable && autoFormProps ? (
    <>
      <DialogContentText>{i18n.t('dialog.delegateTask.content.description')}</DialogContentText>
      <AutoForm defaultValues={defaultValues} id={formId} onSubmit={onSubmit} {...autoFormProps} />
    </>
  ) : (
    <DialogContentText>
      {i18n.t('dialog.delegateTask.content.noUsers', { variables: { name: taskName } })}
    </DialogContentText>
  );

  const ActionsComponent = (
    <>
      <Trigger {...SECONDARY_TRIGGER_PROPS} onClick={onClose}>
        {i18n.t('button.cancel')}
      </Trigger>

      <Trigger {...PRIMARY_TRIGGER_PROPS} onClick={handleSubmitForm} isLoading={isLoading}>
        {i18n.t('button.delegate')}
      </Trigger>
    </>
  );

  return (
    <StackedDialog
      actions={ActionsComponent}
      content={ContentComponent}
      isFullscreen={isMobile}
      handleClose={onClose}
      open={open}
      title={i18n.t('process.dialog.delegateTask.title', { variables: { taskName } })}
      withPadding
    />
  );
};

DelegateTaskDialog.propTypes = {
  entityReference: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default DelegateTaskDialog;
