export const DEFAULT_ENVIRONMENT = {
  BACKEND_SERVER: 'http://localhost:8082/demo',
  GRAPHQL_PATH: '/graphql/react',
};

export const CLASSNAME_ASSIGNMENT = 'Assignment';
export const CLASSNAME_DISPATCH = 'Dispatch';
export const CLASSNAME_DOCUMENT = 'Document';
export const CLASSNAME_FOLDER = 'Folder';
export const CLASSNAME_FUNCLOC = 'Funcloc';
export const CLASSNAME_USER = 'User';

export const RELEVANT_DISPATCH_PATH = '/relevantDispatches';

export const UNCACHED_TYPES = [
  'AttributeType',
  'AttributeValue',
  'EntityReference',
  'EnumValue',
  'GeometryStyle',
  'GeometryType',
  'ProcessAction',
  'RelationType',
];
