import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';

import ReadOnlyField from '@components/ReadOnlyField';
import { Trigger } from '@geomagic/core';
import { AutoForm, submitForm } from '@geomagic/forms';
import { getEntityClass } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import {
  getAutoFormPropsByAttributeType,
  getFormattedAttributeValue,
  getRawAttributeValue,
} from '@geomagic/nam-react-core/utils';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    margin: spacing(1, 0.5),
    minWidth: 120,
    [breakpoints.down('sm')]: {
      flex: 'initial',
      marginTop: spacing(1.5),
      width: '100%',
    },
    [breakpoints.down('md')]: {
      marginBottom: spacing(1),
    },
  },
  autoForm: {
    flex: 1,
    maxWidth: 300,
  },
  trigger: {
    marginLeft: spacing(),
  },
}));

const FORM_ID = 'fieldDataForm';
const FORM_FIELD_ID = 'fieldData';

const getAutoFormProps = pseudoAttributeType => {
  const requiredList = [];
  const { fieldSchema, fieldUI } = getAutoFormPropsByAttributeType(pseudoAttributeType);

  if (pseudoAttributeType.mandatory && !pseudoAttributeType.readOnly) {
    requiredList.push(FORM_FIELD_ID);
  }

  return {
    schema: {
      type: 'object',
      properties: {
        [FORM_FIELD_ID]: fieldSchema,
      },
      required: requiredList,
    },
    ui: {
      [FORM_FIELD_ID]: fieldUI,
    },
  };
};

const ASSIGNMENT_CLASSNAME = 'Assignment';
const FUNCLOC_CLASSNAME = 'Funcloc';

const getAssignmentAttributeTypes = (assignmentTypeId, entityClasses) => {
  const { attributeTypes: classAttributesTypes, entityTypes: assignmentTypes } = getEntityClass(
    entityClasses,
    ASSIGNMENT_CLASSNAME
  );

  if (assignmentTypeId) {
    const { attributeTypes } = assignmentTypes.find(({ id }) => id === assignmentTypeId);

    return [...classAttributesTypes, ...attributeTypes];
  }

  return [...classAttributesTypes];
};

const getFunclocAttributeTypes = (funclocTypeId, entityClasses) => {
  const { attributeTypes: classAttributesTypes = [], entityTypes: funclocTypes = [] } = getEntityClass(
    entityClasses,
    FUNCLOC_CLASSNAME
  );
  const { attributeTypes = [] } = funclocTypes.find(({ id }) => id === funclocTypeId) || {};
  return [...classAttributesTypes, ...attributeTypes];
};

const getAttributType = (
  { assignmentAttribTypeId, assignmentTypeId, funclocAttribTypeId, funclocTypeId },
  entityClasses
) => {
  if (funclocTypeId && funclocAttribTypeId) {
    return getFunclocAttributeTypes(funclocTypeId, entityClasses).find(attr => attr.id === funclocAttribTypeId);
  } else if (assignmentAttribTypeId) {
    return getAssignmentAttributeTypes(assignmentTypeId, entityClasses).find(
      attr => attr.id === assignmentAttribTypeId
    );
  }
  return {};
};

const FormElementFieldData = props => {
  const { context, data, path } = props;

  const classes = useStyles(props);

  const {
    assignmentAttribTypeId,
    assignmentTypeId,
    dataType,
    funclocAttribTypeId,
    funclocEditable,
    funclocTypeId,
    id,
    mandatory,
    name,
    unit,
    value,
    options,
    oldValue,
  } = data;
  const { activeEditField, entityClasses, isReadOnly, onUpdate, setActiveEditField } = context;
  const { allowedValues = [], readOnly = true } =
    getAttributType({ assignmentAttribTypeId, assignmentTypeId, funclocAttribTypeId, funclocTypeId }, entityClasses) ||
    {};
  const pseudoAttributeType = { allowedValues, dataType, mandatory, name, options, readOnly, unit };

  const [autoFormProps] = useState(() => getAutoFormProps(pseudoAttributeType));
  const isEditMode = !!activeEditField;
  const isEditing = activeEditField === id;

  const formattedValue = value != null ? getFormattedAttributeValue(pseudoAttributeType, value) : '';
  const oldFormattedValue =
    oldValue != null ? (
      <>
        <s>{getFormattedAttributeValue(pseudoAttributeType, oldValue)}</s>{' '}
      </>
    ) : (
      ''
    );

  const handleChange = values => {
    const { fieldData } = values;
    const areValuesEqual =
      getRawAttributeValue(pseudoAttributeType, oldValue || value) ===
      getRawAttributeValue(pseudoAttributeType, fieldData);
    const newOldValue = areValuesEqual ? null : !oldValue ? value : oldValue;
    onUpdate({ oldValue: newOldValue, value: fieldData }, path, data);
    setActiveEditField(null);
  };

  return (
    <div className={classes.root}>
      {isEditing ? (
        <div className={classes.autoForm}>
          <AutoForm id={FORM_ID} defaultValues={{ fieldData: value }} onSubmit={handleChange} {...autoFormProps} />
        </div>
      ) : (
        <ReadOnlyField
          formControlProps={{ fullWidth: false }}
          title={name}
          value={
            formattedValue ? (
              <>
                {oldFormattedValue}
                {oldFormattedValue ? <b>{formattedValue}</b> : formattedValue}
              </>
            ) : (
              ''
            )
          }
        />
      )}
      {!isReadOnly && funclocEditable && !readOnly ? (
        isEditing ? (
          <>
            <Trigger
              className={classes.trigger}
              icon={<CheckIcon />}
              onClick={event => submitForm(FORM_ID)}
              tooltip={i18n.t('tooltip.acceptChange')}
            />
            <Trigger
              icon={<ClearIcon />}
              onClick={event => setActiveEditField(null)}
              tooltip={i18n.t('tooltip.cancelChange')}
            />
          </>
        ) : (
          <>
            <Trigger
              className={classes.trigger}
              disabled={isEditMode}
              icon={<EditIcon />}
              onClick={event => setActiveEditField(id)}
              tooltip={i18n.t('tooltip.edit')}
            />
          </>
        )
      ) : null}
    </div>
  );
};

FormElementFieldData.propTypes = {
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default FormElementFieldData;
