import { EMPTY_FILTER_CONFIG } from '@geomagic/geonam-graphql';

const ASSIGNEE_PROCESS_FILTER = {
  discriminator: 'process',
  processFilter: { type: 'assignee', values: [{ operator: 'EQUALS', value: 'current:' }] },
};

const ASSIGNEDTEAM_PROCESS_FILTER = {
  discriminator: 'process',
  processFilter: { type: 'assignedTeam', values: [{ operator: 'EQUALS', value: 'current:' }] },
};

const getEntityFilter = (entityClassNames, withAssigneeFilter = true, withAssignedTeamFilter = false) => {
  return {
    ...EMPTY_FILTER_CONFIG,
    entityClassNames,
    extraFilters: [
      ...(withAssigneeFilter ? [ASSIGNEE_PROCESS_FILTER] : []),
      ...(withAssignedTeamFilter ? [ASSIGNEDTEAM_PROCESS_FILTER] : []),
    ],
  };
};

export default getEntityFilter;
