import React from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  message: {
    marginTop: spacing(),
  },
}));

const useLoadingSnackbar = (props = {}) => {
  const { key = '' } = props;
  const snackbarKeyLoading = `_loading_${key}`;
  const snackbarKeyLoadingFinished = `_loadingFinished_${key}`;

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const getLoadingComponent = text => {
    return (
      <div className={classes.root}>
        <LinearProgress />
        <div className={classes.message}>{text}</div>
      </div>
    );
  };

  const handleExecute = async ({
    loadingText,
    loadingVariant = 'info',
    finishedText,
    finishedVariant = 'info',
    func,
    onError,
  }) => {
    if (loadingText) {
      const LoadingComponent = getLoadingComponent(loadingText);

      enqueueSnackbar(LoadingComponent, {
        key: snackbarKeyLoading,
        persist: true,
        variant: loadingVariant,
      });
    }

    try {
      const result = func && (await func());

      if (finishedText) {
        enqueueSnackbar(finishedText, {
          key: snackbarKeyLoadingFinished,
          preventDuplicate: true,
          variant: finishedVariant,
        });
      }

      return result;
    } catch (error) {
      if (onError) {
        onError(error);
      }
    } finally {
      closeSnackbar(snackbarKeyLoading);
    }
  };

  return handleExecute;
};

useLoadingSnackbar.propTypes = {
  key: PropTypes.string,
};

export default useLoadingSnackbar;
