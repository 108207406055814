import gql from 'graphql-tag';

import { FragmentEntityReference } from '@geomagic/geonam-graphql';

const FragmentEntityPrefetch = gql`
  ${FragmentEntityReference}

  fragment EntityPrefetch on Entity {
    ...EntityReference
    auditInfo {
      createdOn
      createdBy
      modifiedOn
      modifiedBy
    }
    displayName
  }
`;

export default FragmentEntityPrefetch;
